<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
      :title="$t('page.director.title')"
      @edit="$emit('edit')"
    >
      <p>{{ $t('page.director.description') }}</p>
      <p>{{ $t('page.director.upTo') }}</p>
      <professional-order-condition
        :order="storedModel.professionalOrder.order"
        page="director"
      >
      </professional-order-condition>
    </page-title>
    <div
      v-for="(director, index) in model"
      :key="index + rerender"
      class="box"
    >
      <button
        v-if="isViewModeCreate && index !== 0 && index === model.length - 1"
        role="button"
        type="button"
        class="delete"
        tabindex="100"
        @click="removeDirector(index)"
      >
      </button>
      <h3>{{ $t('page.director.section') + ' ' + (index + 1) }}</h3>
      <b-field v-if="isViewModeCreate && index === 0">
        <b-button
          type="is-text is-anchor"
          @click="useFounder"
        >
          {{ $t('page.director.useFounder') }}
        </b-button>
      </b-field>
      <div class="columns">
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('firstname')"
            :type="getFieldType('firstname', index)"
            :message="getFieldMessage('firstname', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.director[${index}].firstname`)"
              v-model="director.firstname"
              @blur="validateFirstname(index)"
              custom-class="page-focus-first"
              :name="`fname${index}`"
              autocomplete="given-name"
            >
            </b-input>
            <span v-else>{{ director.firstname }}</span>
          </b-field>
          <revision-message :path="`$.director[${index}].firstname`"></revision-message>
        </div>
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('lastname')"
            :type="getFieldType('lastname', index)"
            :message="getFieldMessage('lastname', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.director[${index}].lastname`)"
              v-model="director.lastname"
              @blur="validateLastname(index)"
              :name="`lname${index}`"
              autocomplete="family-name"
            >
            </b-input>
            <span v-else>{{ director.lastname }}</span>
          </b-field>
          <revision-message :path="`$.director[${index}].lastname`"></revision-message>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('phone')"
            :type="getFieldType('phone', index)"
            :message="getFieldMessage('phone', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.director[${index}].phone`)"
              v-model="director.phone"
              type="tel"
              @blur="validatePhone(index)"
              :name="`phone${index}`"
              autocomplete="tel"
            >
            </b-input>
            <span v-else>{{ director.phone }}</span>
          </b-field>
          <revision-message :path="`$.director[${index}].phone`"></revision-message>
        </div>
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('birthdate')"
            :type="getFieldType('birthdate', index)"
            :message="getFieldMessage('birthdate', index)"
          >
            <b-datepicker
              v-if="isFieldEditable(`$.director[${index}].birthdate`)"
              v-model="director.birthdate"
              :max-date="atLeastEighteenYearsOld"
              :date-formatter="formatDateWithSlashes"
              icon="calendar-day"
              trap-focus
              @blur="validateBirthdate(index)"
            >
            </b-datepicker>
            <span v-else>{{ formatBirthdate(director.birthdate) }}</span>
          </b-field>
          <revision-message :path="`$.director[${index}].birthdate`"></revision-message>
        </div>
      </div>
      <section-address
        :ref="`directorAddress${index}`"
        :parent-path="`$.director[${index}]`"
        :index="index"
        :model="director"
      >
      </section-address>
    </div>
    <b-field class="has-text-centered">
      <b-button
        v-if="isViewModeCreate && model.length < 3"
        @click="addDirector"
        type="is-text is-anchor"
      >
        {{ $t('page.director.add') }}
      </b-button>
    </b-field>
  </fieldset>
</template>

<script>
import page from '@/mixins/page';
import date from '@/mixins/date';
import SectionAddress from './SectionAddress';
import ProfessionalOrderCondition from '../form/ProfessionalOrderCondition';

export default {
  name: 'PageDirector',
  mixins: [
    page('director'),
    date
  ],
  components: {
    ProfessionalOrderCondition,
    SectionAddress
  },
  data () {
    return {
      rerender: 0
    };
  },
  methods: {
    addDirector () {
      this.model.push({
        firstname: '',
        lastname: '',
        address: '',
        city: '',
        postalcode: '',
        province: 'QC',
        role: '',
        birthdate: undefined,
        ssn: '',
        assessmentnumber: '',
        isDirector: false,
        isTreasurer: false,
        isSecretary: false
      });
    },
    removeDirector (index) {
      this.model.splice(index, 1);
      this.removeError(`birthdate${index}`);
      this.removeError(`firstname${index}`);
      this.removeError(`lastname${index}`);
      this.removeError(`phone${index}`);
    },
    rerenderArray () {
      this.rerender += this.model.length;
    },
    useFounder () {
      document.activeElement?.blur();
      const fields = ['firstname', 'lastname', 'birthdate', 'address', 'city', 'province', 'postalcode', 'phone'];
      fields.forEach(key => {
        this.model[0][key] = this.storedModel.founder[key];
      });
      this.rerenderArray();
      this.validateBirthdate(0);
      this.validateFirstname(0);
      this.validateLastname(0);
      this.validatePhone(0);
    },
    validate () {
      this.model.forEach((director, index) => {
        this.validateBirthdate(index);
        this.validateFirstname(index);
        this.validateLastname(index);
        this.validatePhone(index);

        const addressErrors = this.$refs[`directorAddress${index}`][0].validate();
        if (addressErrors?.length)
          this.addError(`directorAddress${index}`);
        else
          this.removeError(`directorAddress${index}`);
      });
    },
    validateBirthdate (index) {
      if (!this.model[index].birthdate)
        this.addError(`birthdate${index}`);
      else
        this.removeError(`birthdate${index}`);
    },
    validateFirstname (index) {
      this.validateNotEmpty(`firstname${index}`, this.model[index].firstname);
    },
    validateLastname (index) {
      this.validateNotEmpty(`lastname${index}`, this.model[index].lastname);
    },
    validatePhone (index) {
      if (!this.hasIncorporationAccess) this.validateMinLength(`phone${index}`, this.model[index].phone, 10);
    }
  }
};
</script>
